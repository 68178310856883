html,
body,
div,
span,
applet,
object,
iframe,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Apple SD Gothic Neo";
  word-break: keep-all;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.bi-search {
  float: right;
  margin-right: 10px;
  font-size: 5rem;
  color: #555555;
}
html,
body,
#root {
  font-size: calc(100vw / 1440 * 10);
  position: relative;
  background: #edeef6;
  width: 100%;
  height: 100%;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 4px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
  height: 40%; /* 스크롤바의 길이 */
  background: #d6d6d6; /* 스크롤바의 색상 */
  border-radius: 10rem;
}

::-webkit-scrollbar-track {
  background: transparent; /*스크롤바 뒷 배경 색상*/
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: 2 !important;
}

input[type="checkbox"] {
  width: 1.6rem;
  height: 1.6rem;
}
